import React, { useState } from 'react'
import styled from 'styled-components'
import { GrLocation } from "react-icons/gr";
import { ModalRecommendation } from './ModalRecommendation';

const Wrapper = styled.div`
  position: relative;
  background-color: ${props => `${props.theme.colors.CustomGray}`};   
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    height: 50px;
    margin-top: 5rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    margin-top: 4rem;
    height: 40px;
  }

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  color: ${props => `${props.theme.colors.CustomRed}`};
  font-family: ${props => props.theme.fonts[0]};
  padding: 0 50px 0 0;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: .9rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: .8rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .7rem;
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  font-family: ${props => props.theme.fonts[0]};
  color: ${props => `${props.theme.colors.CustomGrayFont}`};
  borderRadius: 5;
  padding: 0px;
  &:focus {
    outline: none;
    background-color: lightgray;
    box-shadow: 0px 0px 2px gray;
  }
`;

const ButtonRecommendation = styled(Button)`
  display:flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  color: ${props => `${props.theme.colors.CustomGrayFont}`};  
  &:focus {
    outline: none;
    background-color: transparent;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: .9rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: .8rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .7rem;
  }
`;

const Icon = styled.img`
  margin-right: 10px;  
  display: block;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    height: 20px;
    width: 20px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    height: 18px;
    width: 18px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    height: 16px;
    width: 16px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    height: 14px;
    width: 14px;
  }
`;

export const GrayHeader = () => {
  const [recommendationShow, setRecommendationShow] = useState(false);

  const RecommendationButtonHandler = () => {
    setRecommendationShow(true);
  }

  return (
    <Wrapper>
      <SubWrapper>
        <Title>Mi <b>FarmaciaCVS</b></Title>
        <ButtonRecommendation onClick={RecommendationButtonHandler}><Icon src={require('../Images/farmacvs_GxD_webdesign_recomendacionesmed_32x32.png')} />Recomendaciones Médicas</ButtonRecommendation>
      </SubWrapper>
      <ButtonRecommendation><GrLocation />&nbsp;CCCT</ButtonRecommendation>
      <ModalRecommendation
        show={recommendationShow}
        onHide={() => setRecommendationShow(false)}
      />
    </Wrapper>
  )
}