import React, { useState } from 'react'
import styled from 'styled-components'
import History from '../History';
import { ModalScheduleDate } from './ModalScheduleDate';

const Wrapper = styled.div`
  position: relative;
  height: 150px;
  border-bottom: 1px solid gray;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    height: 150px;
    padding: 0 60px;
  }
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    height: 110px;
    padding: 0 50px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 40px;
    border-bottom: none;
    margin-top: 5rem;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 20px;
    border-bottom: none;
    margin-top: 4rem;
  }
`;

const WrapperButtons = styled.div`
  position:absolute; 
  bottom:0;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  padding: 5px 60px;  
  color: ${props => props.theme.colors.CustomGrayFont};
  outline: none !important;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.2rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: 1.1rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .9rem;
  }
`;

const FirstButton = styled(Button)`
  padding: 0 60px 0 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-top: 10px;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 100%
  } 
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 100%
  }
`;

const LogoSubWrapper = styled.div`
  display: inherit;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoRif = styled.div`
  color: ${props => props.theme.colors.CustomRed};
  font-family: ${props => props.theme.fonts[1]};

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: .6rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: .5rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`;

const Logo = styled.img`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 18%;
  }
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 20%;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 35%
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 50%;
    height: 40px;
  }
`;

const Icon = styled.img`
  color: ${props => props.theme.colors.CustomGrayFont};
  width: 30px;
  height: 30px;
  display: none;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: block;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: block;
  }
`;

const Links = styled.a`
  color: ${props => props.theme.colors.CustomGrayFont};;
  &:hover{
    color: ${props => props.theme.colors.CustomGrayFont};
    text-decoration: none;
  }
`;

export const WhiteHeader = (props) => {
  const [scheduleShow, setScheduleShow] = useState(false);
  const hideItems = props.hideIt;

  const ScheduleButtonHandler = () => {
    setScheduleShow(true);
  }

  const GoHome = () => {
    History.push('/')
  }

  return (
    <Wrapper style={hideItems ? { borderBottom: "0" } : {}}>
      <LogoWrapper onClick={GoHome}>
        <LogoSubWrapper>
          <Logo src={require('../Images/farmacvs_GxD_logopuyao_png_512x512.png')} alt="Home Logo" />
          <Icon src={require('../Images/farmacvs_GxD_webdesign_png_greylocation_64x64-01.png')} alt="Location" />
        </LogoSubWrapper>
        <LogoRif>J-50037548-4</LogoRif>
      </LogoWrapper>
      <WrapperButtons style={hideItems ? { display: "none" } : {}}>
        <FirstButton><Links href="/#SECOND_SECTION">Farmacia</Links></FirstButton>
        <Button><Links href="/#SECOND_SECTION">Tienda</Links></Button>
        <Button onClick={ScheduleButtonHandler}>Consultas</Button>
        <Button onClick={ScheduleButtonHandler}>Exámenes Médicos</Button>
      </WrapperButtons>
      <ModalScheduleDate
        show={scheduleShow}
        onHide={() => setScheduleShow(false)}
      />
    </Wrapper>
  )
}