import React from 'react'
import styled from 'styled-components'
import { ImWhatsapp } from "react-icons/im";
import ReactPixel from 'react-facebook-pixel';

import { FirstSection } from '../Components/FirstSection'
import { Footer } from '../Components/Footer'
import { Header } from '../Components/Header'
import { SecondSection } from '../Components/SecondSection'
import { ThirdSection } from '../Components/ThirdSection'

const Wrapper = styled.div({
  position: 'relative',
  width: "100%",
  height: "auto"
})

const WhatsappButton = styled.a`
  position:fixed;	
	bottom:40px;
	right:60px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  
	box-shadow: 2px 2px 3px #999;
  z-index:100;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: white;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.8rem;
    width: 60px;
    height: 60px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: 1.4rem;
    width:56px;
    height:56px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: 1.3rem;
    width: 50px;
    height: 50px;
    right: 25px;
  }
`;

export const Home = () => {

  const WhatsAppButton = () => {
    ReactPixel.track('Contact', {
      content_name: "Home"
    })
  }

  return (
    <Wrapper>
      <Header />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <Footer />
      <WhatsappButton onClick={WhatsAppButton} href="https://wa.me/584122090826" target="_blank">
        <ImWhatsapp />
      </WhatsappButton>
    </Wrapper>
  )
}