import React from 'react'
import styled from 'styled-components'

import History from '../History';

const SideNav = styled.div`
  height: 100%; /* 100% Full-height */
  position: fixed; /* Stay in place */
  display: block;
  width: 80%;
  height: 0;
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: white;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px; /* Place content 60px from the top */
  transition: opacity 0.5s, height 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  opacity: 0;
  overflow: hidden;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    margin-top: 5rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-top: 4rem;
  }
`;

const SideOption = styled.button`
  width: 100%;
  text-align: start;
  padding: 15px 8px 15px 32px;
  text-decoration: none;
  font-size: 1rem;
  color: ${props => props.theme.colors.CustomGrayFont};
  display: block;
  transition: 0.3s;
  border: none;
  background-color: transparent;
  &:hover{
    outline: none !important;
    background-color: ${props => props.theme.colors.CustomAqua};
  };
  &:focus:{
    outline: none !important;
    background-color: transparent;
  }

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 30px 0px 20px 50px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 30px 0px 20px 30px;
  }
`;

export const SideMenu = (props) => {

  const FarmaHandler = () => {
    History.push('/#SECOND_SECTION')
    props.onHideMenu();
  }

  const DeliveryButtonHandler = () => {
    props.onModalDelivery(true);
    props.onHideMenu();
  }

  const ScheduleButtonHandler = () => {
    props.onModalSchedule(true);
    props.onHideMenu();
  }

  const RecommendationButtonHandler = () => {
    props.onModalRecommendation(true);
    props.onHideMenu();
  }

  return (
    <SideNav style={{ opacity: props.Opa, height: props.height }}>
      <SideOption onClick={FarmaHandler}>Farmacia</SideOption>
      <SideOption onClick={FarmaHandler}>Tienda</SideOption>
      <SideOption onClick={ScheduleButtonHandler}>Consulta</SideOption>
      <SideOption onClick={ScheduleButtonHandler}>Exámen Médico</SideOption>
      <SideOption onClick={DeliveryButtonHandler}>Servicio delivery</SideOption>
      <SideOption onClick={RecommendationButtonHandler}>Recomendaciones Médicas</SideOption>
    </SideNav>
  )
}