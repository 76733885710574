import React from 'react';
import { Carousel } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.div`
  @media (min-width: 1025px){
    padding: 0 60px 50px 60px;  
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){}

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
    padding: 0;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
    padding: 0;
  }
`;

const BannerImage = styled.img`
  width: 100%;
`;

export const ThirdSection = () => {
  return (
    <Wrapper>
      <Carousel controls={false} pause={false}>
        <Carousel.Item interval={1000}>
          <BannerImage
            src={require('../Images/farmacvs_GxD_webdesign_promo_banner_distancing2_1750x440.png')}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <BannerImage
            src={require('../Images/farmacvs_GxD_webdesign_promo_banner_hands_1750x440.png')}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <BannerImage
            src={require('../Images/farmacvs_GxD_webdesign_512X512_bannerpng_quedateencasa.png')}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </Wrapper>
  )
}