import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init('3069965676616567', {}, { debug: true, autoConfig: false });
//3069965676616567 New 2
//240855957529042 New
//431219904981330 Old
ReactPixel.pageView();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
