import React from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .8rem;
  }
`;

const Title = styled.div`
  font-family: ${props => props.theme.fonts[1]};
  text-transform: uppercase;
  color: ${props => props.theme.colors.CustomRed};  

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.2rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    font-size: 1rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1.4rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .9rem;
    margin: 20px 0;
  }
`;

const ButtonClose = styled.button`
    border: none !important;
    position: absolute;
    right: 0px;
    background-color: transparent;
    border: none;
    color: white;        
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &:hover{
      background-color: rgb(208, 211, 212, .5);
    };
    &:focus{
      outline: none;
      background-color: transparent;
    };
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 32px;
    height: 32px;
    padding: 20px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    width: 24px;
    height: 24px;
    padding: 16px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 24px;
    height: 24px;
    padding: 20px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
     width: 24px;
    height: 24px;
    padding: 0px;
  }
`;

const Icon = styled.img`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 32px;
    height: 32px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    width: 24px;
    height: 24px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 32px;
    height: 32px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 24px;
    height: 24px;
  }
`;

const TitlesWrap = styled.div`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    padding: 10px 30px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    padding: 10px 20px; 
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 30px 30px; 
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 5px 5px;
  }
`;

const TitleBodyOne = styled.div`
  color: ${props => props.theme.colors.CustomGrayFont}; 
  font-family: ${props => props.theme.fonts[0]};

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.2rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    font-size: 1.2rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1.5rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: 1rem;
  }
`;

const TitleBodyTwo = styled.div`
  color: ${props => props.theme.colors.CustomRed};  
  font-family: ${props => props.theme.fonts[1]};
  text-transform: uppercase;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.4rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: 1.4rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1.8rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: 1.3rem;
    padding: 5px 0px;
  }
`;

const TitleBodyThree = styled(TitleBodyOne)`
  letter-spacing: 1.5px;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    border-bottom: 1px solid gray;
    padding-bottom: 40px;
  }
`;

const BlockWrapper = styled.div`
  border-top: 1px solid gray;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    padding: 40px 30px 15px 30px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    padding: 15px 0px 0px 0px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 10px 30px 0px 30px;
    flex-direction: column;
    border-top: none;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 10px 0px 0px 0px;
    flex-direction: column;
    border-top: none;
  }
`;

const Block = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 100%;
  }  
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 100%;
  }
`;

const BlockReserve = styled(Block)`  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    flex-direction: row-reverse;
    margin: 10px 0;
  }  
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    flex-direction: row-reverse;
    margin: 10px 0;
  }
`;

const BlockImage = styled.img`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 100px;
    height: 100px; 
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 100px;
    height: 100px; 
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 110px;
    height: 110px;  
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 90px;
    height: 90px; 
  }
`;

const BlockText = styled.p`
  color: ${props => props.theme.colors.CustomGrayFont}; 
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: .9rem;
    padding: 2px 20px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    font-size: .9rem;
    padding: 2px 20px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1.1rem;
    padding: 2px 7px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .8rem;
    padding: 2px 7px;
  }
`;

const LogoWrap = styled.div`
  text-align: right;
  border-bottom: 1px solid gray;  
  padding-bottom: 15px;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    margin-bottom: 40px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    margin-bottom: 20px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding-bottom: 0px;
    border-top: 1px solid gray; 
    border-bottom: none;
    padding-top: 15px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding-bottom: 0px;
    border-top: 1px solid gray; 
    border-bottom: none;
    padding-top: 15px;
  }
`;

const Logo = styled.img`
  width: 16%;
  margin-left: -.5%;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 45%;
    padding: 20px;
  }  
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 60%; 
    margin-left: -.10%;
  }
`;


export const ModalRecommendation = (props) => {
  ReactModal.setAppElement('#root');
  
  return (
    <ReactModal
      isOpen={props.show}
      className="recommendation-modal"
      overlayClassName="delivery-overlay"
      onRequestClose={props.onHide} 
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <Wrapper>
        <Title>Recomendaciones Médicas</Title>
        <ButtonClose onClick={props.onHide}>
          <Icon src={require('../Images/farmacvs_GxD_webdesign_png_xmodal_32x32.png')} />
        </ButtonClose>
      </Wrapper>
      <TitlesWrap>
        <TitleBodyOne>
          Cómo minimizar el riesgo de contraer
        </TitleBodyOne>
        <TitleBodyTwo>
          Covid-19
        </TitleBodyTwo>
        <TitleBodyThree>
          Trabajemos en equipo para cuidarnos
        </TitleBodyThree>
      </TitlesWrap>
      <BlockWrapper style={{ marginTop: '10px' }}>
        <Block>
          <BlockImage src={require('../Images/farmacvs_GxD_webdesign_png_recomendaciones1_64x64-01.png')} alt="" />
          <BlockText>Lave sus manos con frecuencia. Limpie y desinfecte las superficies con regularidad</BlockText>
        </Block>
        <BlockReserve>
          <BlockImage src={require('../Images/farmacvs_GxD_webdesign_png_recomendaciones2_64x64.png')} alt="" />
          <BlockText>Limite las salidas lo más posible y procure que sean de carácter básico:
            comprar alimentos, gasolina y otras necesidades. En cada caso, utilice la mascarilla.</BlockText>
        </BlockReserve>
      </BlockWrapper>
      <BlockWrapper>
        <Block>
          <BlockImage src={require('../Images/farmacvs_GxD_webdesign_png_recomendaciones3_64x64-01.png')} alt="" />
          <BlockText>Si debe salir, evite el contacto innecesario con otras personas y las áreas comunes.
            Guarde siempre la distancia corrspondiente</BlockText>
        </Block>
        <BlockReserve>
          <BlockImage src={require('../Images/farmacvs_GxD_webdesign_png_recomendaciones4_64x64-01.png')} alt="" />
          <BlockText>Solo permita la entrada de visitantes que presten servicios esenciales y que no
            puedan ser resultos a distancia. Evite siempre el contacto en la medida de lo posible.</BlockText>
        </BlockReserve>
      </BlockWrapper>
      <LogoWrap>
        <Logo src={require('../Images/farmacvs_GxD_logopuyao_png_512x512.png')} alt="" />
      </LogoWrap>
    </ReactModal>
  )
}