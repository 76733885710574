import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';

const Wrapper = styled.div({
  position: 'relative',
  width: "100%",
  height: "97vh"
})

const SubWrapper = styled.div`
  padding: 0 60px;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 60px 0px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 0 0;
  }
`

const Title = styled.div`
  color: ${props => props.theme.colors.CustomRed};
  font-family: ${props => props.theme.fonts[1]};
  font-size: 3rem;
  padding-top: 30px;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1.4rem;
    padding-top: 10px;
    padding: 0 40px;
  } 

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: 1rem;
    padding-top: 10px;
    padding: 0 20px;
  }
`;

const SubTitle = styled.div`
  color: ${props => props.theme.colors.CustomGrayFont};
  font-family: ${props => props.theme.fonts[0]};
  font-size: 2rem;
  padding-bottom: 30px;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1rem;
    padding: 0 20px 20px 40px;
  } 

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .7rem;
    padding: 0 20px 20px 20px;
  }
`;

const ImageCustom = styled.img`
  width: 100%;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    border-radius: 25px;
  } 
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    border-radius: 25px;
  }
`;

const FooterWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: none;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: block;
  } 
  @media (max-width: 760px){
    display: block;
  }
`

export const Promotion = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Wrapper>
      <Header />
      <SubWrapper>
        <Title>Promociones</Title>
        <SubTitle>Octubre</SubTitle>
        <picture>
          <source media="(min-width: 768px ) and (max-width: 1024px)" srcSet={require('../Images/Tablet/farmacvs_GxD_psd_webdesign_promobanner_tablet.jpg')} />
          <source media="(max-width: 760px)" srcSet={require('../Images/Mobile/farmacvs_GxD_webdesign_jpeg_promobanner_mobile.jpg')} />
          <ImageCustom src={require('../Images/farmacvs_GxD_webdesign_promo_banner_opening_1750x800.png')} alt="Promotion" />
        </picture>
      </SubWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Wrapper>
  )
};