import React from 'react'
import styled from 'styled-components'
import { SiInstagram } from "react-icons/si";
import { Header } from '../Components/Header';
import History from '../History';

const Wrapper = styled.div({
  position: 'relative',
  width: "100%",
  height: "100vh"
})

const SubWrapper = styled.div`
  padding: 0 0px;
`

const ImageSection = styled.img`
  width: 100%;
  padding: 50px 60px;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 30px 30px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-top: -10px;
    margin-bottom: -30px;
    padding: 50px 20px;
  }
`;

const ImageSectionLast = styled(ImageSection)`
  padding: 0px 0px;
  margin-bottom: 50px;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-bottom: 20px;
  }
`;

const Footer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.CustomGrayFont};  
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    height: 150px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    height: 110px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    height: 80px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 0 20px;
    height: 90px;
    margin-top: -20px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column; 
  cursor: pointer;
`;

const CopyrightLabel = styled.div`
  color: white;
  font-family: ${props => props.theme.fonts[0]};
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1rem;
  }
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: .9rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: .8rem;
  }  
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .5rem;
    margin-left: -40px;
  }
`;

const LogoRif = styled.div`
  color: white;
  font-family: ${props => props.theme.fonts[0]};

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: .6rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: .5rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: .3rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .2rem;
  }
`;

const Logo = styled.img`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    height: 50px
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    height: 40px
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    height: 30px
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    height: 25px
  }
`;

const Instagram = styled.div`
  color: white; 

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.7rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: 1.5rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1.3rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: 1.1rem;
  }
`;

export const AboutUs = () => {

  const GoHome = () => {
    History.push('/')
  }

  return (
    <Wrapper>
      <Header hideIt={true} />
      <SubWrapper>
        <picture>
          <source media="(min-width: 768px ) and (max-width: 1024px)" srcSet={require('../Images/Tablet/farmacvs_GxD_jpg_webdesign_aboutus1_tablet.jpg')} />
          <source media="(max-width: 760px)" srcSet={require('../Images/Mobile/farmacvs_GxD_webdesign_jpeg_aboutus1_mobile.jpg')} />
          <ImageSection src={require('../Images/farmacvs_GxD_webdesign_jpg_aboutus1_web_text.jpg')} alt="Quienes Somos" />
        </picture>
        <picture>
          <source media="(min-width: 768px ) and (max-width: 1024px)" srcSet={require('../Images/Tablet/farmacvs_GxD_jpg_webdesign_aboutus2_tablet.jpg')} />
          <source media="(max-width: 760px)" srcSet={require('../Images/Mobile/farmacvs_GxD_psd_webdesign_aboutus2_mobile_2.jpg')} />
          <ImageSection src={require('../Images/farmacvs_GxD_webdesign_jpg_aboutus2_web_text2.jpg')} alt="Todo en un Lugar" />
        </picture>
        <picture>
          <source media="(min-width: 768px ) and (max-width: 1024px)" srcSet={require('../Images/Tablet/farmacvs_GxD_jpg_webdesign_valores_tablet.jpg')} />
          <source media="(max-width: 760px)" srcSet={require('../Images/Mobile/farmacvs_GxD_webdesign_jpeg_valorestext_mobile.jpg')} />
          <ImageSection src={require('../Images/farmacvs_GxD_webdesign_jpg_valores_web_text.jpg')} alt="Valores" />
        </picture>
        <picture>
          <source media="(min-width: 768px ) and (max-width: 1024px)" srcSet={require('../Images/Tablet/farmacvs_GxD_webdesign_jpeg_farmafamily_tablet.jpg')} />
          <source media="(max-width: 760px)" srcSet={require('../Images/Mobile/farmacvs_GxD_webdesign_jpeg_farmafamily_mobile.jpg')} />
          <ImageSection src={require('../Images/farmacvs_GxD_webdesign_jpg_farmafamily_web_text.jpg')} alt="" />
        </picture>
        <picture>
          <source media="(min-width: 768px ) and (max-width: 1024px)" srcSet={require('../Images/Tablet/farmacvs_GxD_jpg_webdesign_aboutusfooter_tablet.jpg')} />
          <source media="(max-width: 760px)" srcSet={require('../Images/Mobile/farmacvs_GxD_webdesign_jpeg_aboutusfooter_mobile.jpg')} />
          <ImageSectionLast src={require('../Images/farmacvs_GxD_webdesign_jpg_aboutusfooter_web.jpg')} alt="" />
        </picture>
        <Footer>
          <LogoWrapper onClick={GoHome} >
            <Logo src={require('../Images/farmacvs_GxD_logopuyaowhite_png_512x512-01.png')} alt="" />
            <LogoRif>J-50037548-4</LogoRif>
          </LogoWrapper>
          <CopyrightLabel>Copyright 2020-2021</CopyrightLabel>
          <a href="https://www.instagram.com/farmacvs/" rel="noopener noreferrer" target="_blank"><Instagram><SiInstagram /></Instagram></a>
        </Footer>
      </SubWrapper>
    </Wrapper>
  )
};