import React from 'react'
import styled from 'styled-components'
import { Header } from '../Components/Header';

const Wrapper = styled.div({
  position: 'relative',
  width: "100%",
  height: "100vh"
})

const SubWrapper = styled.div`
  padding: 0 60px;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 60px 0px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 0 0;
  }
`

const Title = styled.div`
  color: ${props => props.theme.colors.CustomRed};
  font-family: ${props => props.theme.fonts[1]};
  padding-top: 30px;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1.4rem;
    padding-top: 10px;
    padding: 0 40px;
    margin-bottom: 30px;
  } 

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: 1rem;
    padding-top: 10px;
    padding: 0 20px;
  }
`;

const Paragraph = styled.p`
  width: 60%;
  font-size: .8rem;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 90%;
    padding: 0px 40px;
  } 

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 90%;
    padding: 5px 20px;
  }
`;

export const Policy = () => {
  return (
    <Wrapper>
      <Header />
      <SubWrapper style={{ paddingBottom: "50px" }}>
        <Title>Políticas de Privacidad</Title>
        <Paragraph>
          <b>Farmacia Farma CVS C.A.</b>, presta un servicio integral a <b>LOS USUARIOS</b> de su página web, y por cuanto la información es un elemento fundamental para sus operaciones, mantener la privacidad de la información suministrada es una de sus prioridades.
        </Paragraph>
        <Paragraph>
          <b>Farmacia Farma CVS C.A.</b> hará sus mejores esfuerzos para alcanzar este fin. Sin embargo, esto no puede lograrse si <b>EL USUARIO</b> no proporciona cierto tipo de información personal fundamental que es necesaria para hacer funcionar el servicio, y en muchos casos suministrar información a terceros, cuando sea permitido y necesario.
        </Paragraph>
        <Paragraph>
          Por lo antes mencionado, <b>Farmacia Farma CVS C.A.</b> ha diseñado y puesto a la disposición de <b>LOS USUARIOS</b> de su página web esta Política o Declaración de Privacidad a efectos de salvaguardar la información de los mismos, mostrarles su compromiso a mantener la privacidad y confidencialidad de la información personal suministrada, y establecer las modalidades y situaciones en las cuales EL USUARIO puede hacer uso de dicha información o limitar el uso de la misma, todo de conformidad con lo dispuesto en el Artículo 37 (Privacidad y Confidencialidad) del Capítulo V (de la Protección en el Comercio Electrónico), de LA LPCU. A través de esta declaración informativa, <b>Farmacia Farma CVS C.A.</b> pretende informar a <b>LOS USUARIOS</b> de su página web, entre otros, sobre los siguientes particulares:
        </Paragraph>
        <Paragraph>
          a) Qué información obtiene <b>Farmacia Farma CVS C.A.</b> y qué seguimiento hace de dicha información.
        </Paragraph>
        <Paragraph>
          b) Qué hace <b>Farmacia Farma CVS C.A.</b> con la información obtenida de su página web.
        </Paragraph>
        <Paragraph>
          c) Qué opciones tiene <b>EL USUARIO</b> para acceder, modificar y/o borrar dicha información
        </Paragraph>
        <Paragraph>
          <br /><b>Farmacia Farma CVS C.A.</b> declara expresamente, para todos los efectos del Artículo 38 de LA LPCU que el suministro de información de <b>LOS USUARIOS</b> o consumidores no forma parte integrante del modelo de negocio desarrollado por <b>Farmacia Farma CVS C.A.</b>. Dicha información es obtenida sólo a los fines establecidos en los Contratos que se suscriben con los clientes, según sea el caso, para mejorar su gestión y para otros propósitos estadísticos y de monitoreo.
        </Paragraph>
        <Paragraph>
          Qué información obtiene <b>Farmacia Farma CVS C.A.</b> y qué seguimiento hace de dicha información: <b>Farmacia Farma CVS C.A.</b>, obtiene información de sus usuarios, a través de diversos métodos: la llamada tecnología de galletas o “cookies”, y cuando <b>EL USUARIO</b> suministra información a través de los diversos formularios o ventanas disponibles en esta página web.
        </Paragraph>
        <Paragraph>
          Cuando <b>EL USUARIO</b> envía información hacia <b>Farmacia Farma CVS C.A.</b>, la misma es almacenada para ser revisada, analizada y recuperada posteriormente. La información obtenida de <b>EL USUARIO</b> a través de las galletas o “cookies” y cualquier otro de los medios mencionados, es usada como información demográfica sobre la audiencia de <b>Farmacia Farma CVS C.A.</b> con el propósito de mejorar sus servicios. <b>EL USUARIO</b> entiende y así lo acepta, que puede voluntariamente configurar su navegador para no aceptar “cookies”, por lo que es de su entera responsabilidad el aceptar dichos archivos en su máquina. La información entregada por usuario, permite a <b>Farmacia Farma CVS C.A.</b> informar al <b>USUARIO</b> acerca de actualizaciones o servicios nuevos que <b>Farmacia Farma CVS C.A.</b>, sus filiales o terceros puedan tener disponibles y los cuales considera pueden ser de su interés.
        </Paragraph>
        {/* <Paragraph>
          Entre la información personal que <b>Farmacia Farma CVS C.A.</b> podrá recolectar se encuentran en adición a la información suministrada por el propio USUARIO, la siguiente: dirección de IP; fecha y hora de la solicitud; página visitada anteriormente; total de bytes transmitidos y recibidos; tiempo consumido; protocolo y estado del protocolo, página visitada, así como cualquier otra información de la computadora usada por el <b>USUARIO</b> para navegar tales como datos del navegador, resolución del monitor, versión de Java, JavaScript, etc.
        </Paragraph> */}
        <Paragraph>
          <b>Farmacia Farma CVS C.A.</b> podrá compartir información estadística (en ningún caso personal) con sus publicistas, para lograr un mejor entendimiento de los servicios ofrecidos al público y poder monitorear el comportamiento del público hacia dicha publicidad.
        </Paragraph>
        <Paragraph>
          <b>El USUARIO</b> garantiza que los Datos Personales suministrados a <b>Farmacia Farma CVS C.A.</b> son veraces y se hace responsable de comunicarle a ésta cualquier modificación que los mismos puedan experimentar.
        </Paragraph>
        <Paragraph>
          Qué hace <b>Farmacia Farma CVS C.A.</b> con la información obtenida de su página web: <b>Farmacia Farma CVS C.A.</b> asume el compromiso de no compartir con terceros información personal suministrada por EL USUARIO, sin haber obtenido su previo consentimiento. Lo anterior se extiende a cualquier tipo de información, incluyendo cualquiera de los aspectos mencionados en la letra anterior.
        </Paragraph>
        <Paragraph>
          <b>Farmacia Farma CVS C.A.</b> se obliga a dar cumplimiento con la obligación mencionada, excepto en los siguientes casos:
        </Paragraph>
        <Paragraph>
          - Cuando <b>EL USUARIO</b> ha otorgado su consentimiento de forma expresa, bajo cualquier modalidad, incluyendo el registro de <b>EL USUARIO</b>, formularios, etc.
        </Paragraph>
        <Paragraph>
          - Cuando sea necesario, a los efectos de proteger a <b>LOS USUARIOS</b> de un uso no autorizado, incluyendo cualquier intento de un usuario trasgresor de causar un daño o interrumpir el uso normal de los servicios de <b>Farmacia Farma CVS C.A.</b> que sea considerado como un uso anormal de dichos servicios.
        </Paragraph>
        <Paragraph>
          - Específicamente, cuando <b>Farmacia Farma CVS C.A.</b> necesite suministrar información a empresas consultoras que realicen trabajos para el seguimiento de presuntas violaciones a políticas y/o bienes de <b>Farmacia Farma CVS C.A.</b> tales como en el caso de violación de derechos de autor y similares.
        </Paragraph>
        <Paragraph>
          - Cuando le sea requerido a <b>Farmacia Farma CVS C.A.</b> por una autoridad competente.
        </Paragraph>
        <Paragraph>
          - Cuando a juicio de <b>Farmacia Farma CVS C.A.</b> deba compartir información con empresas que realizan labores conjuntas con <b>Farmacia Farma CVS C.A.</b>, tales como motores de búsqueda, lo cual se traducirá en un mejor servicio al usuario. En estos casos, <b>Farmacia Farma CVS C.A.</b> tomará las previsiones del caso para que dichas empresas asuman por escrito el compromiso de guardar la confiencialidad y privacidad de dicha información.
        </Paragraph>
        <Paragraph>
          <br />Qué opciones tiene <b>El USUARIO</b> para acceder, modificar y/o borrar dicha información: <b>El USUARIO</b> está en capacidad de decidir si suministra o no información que le sea solicitada por <b>Farmacia Farma CVS C.A.</b>, bien notificando su deseo a través de las direcciones de email disponibles, o configurando su navegador para recibir o no galletas (cookies).
        </Paragraph>
        {/* <Paragraph>
          <b>LOS USUARIOS</b> registrados tendrán acceso, en todo momento a su información de registro, para determinar la exactitud de dicha información y efectuar los cambios que consideren apropiados, o limitar el uso de la misma por parte de <b>Farmacia Farma CVS C.A.</b> deconformidad con lo establecido en el Artículo 38 de LA LPCU, pudiendo ejercer tales derechos, a través de cualesquiera medios disponibles en <b>www.farmaciacvs.com</b>, o a través de los números telefónicos y/ fax de la empresa, disponibles en su página Web.
        </Paragraph> */}
      </SubWrapper>
    </Wrapper>
  )
};