import React, { useState } from 'react'
import styled from 'styled-components'
import { ModalDelivery } from './ModalDelivery';
import { ModalScheduleDate } from './ModalScheduleDate';
import { ModalRecommendation } from './ModalRecommendation';
import { SideMenu } from './SideMenu';
import { AiOutlineMenu, AiOutlineCloseCircle } from "react-icons/ai";

const Wrapper = styled.div`
  position: fixed;
  background-color: ${props => `${props.theme.colors.CustomRed}`};
  width: 100%;  
  padding: 0 60px;
  z-index: 99;
  top: 0;
  
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    height: 5rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    height: 4rem;
  }

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    height: 5rem; 
    padding: 0 20px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    height: 4rem;
    align-items: center;
    padding: 0 20px;
  }
`;

const Title = styled.label`
  color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);   
  font-family: ${props => props.theme.fonts[0]};
  text-shadow: 2px 2px #000000;
  cursor: pointer;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.4rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    font-size: 1.3rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`;

const Icon = styled.img`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    height: 52px;
    width: 52px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    height: 48px;
    width: 48px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 24px;
    height: 24px;
  }  

  /* SMALL SCREEN > MO00BILE */
  @media (max-width: 760px){
    width: 24px;
    height: 24px;
  }  
`;

const ButtonClose = styled.button`
    border: none !important;
    display: flex;
    background-color: transparent;
    color: white;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &:hover{
      background-color: rgb(208, 211, 212, .5);
    };
    &:focus{
      outline: none;
      background-color: transparent;
    };
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    display: none;
  }
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 24px;
    height: 24px;
    padding: 16px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 48px;
    height: 48px;
    padding: 0px;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-top: .8rem;
    font-size: .8em;
    width: 32px;
    height: 32px;
    padding: 0px;
  }
`;

const WrapperMenu = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: white;
  font-size: 2.3rem;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    top: 50%;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    top: 45%;
    font-size: 1.9rem;
  }
`;

export const RedHeader = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalDeliveryShow, setModalDeliveryShow] = useState(false);
  const [scheduleShow, setScheduleShow] = useState(false);

  const [sideMenuOpacity, setSideMenuOpacity] = useState(0);
  const [sideMenuHeight, setSideMenuHeight] = useState(0);

  const TitleHandler = () => {
    setModalDeliveryShow(true)
  }

  const OpenSideMenu = () => {
    if (sideMenuOpacity === 0) {
      setSideMenuOpacity(1)
      setSideMenuHeight(100)
    } else {
      setSideMenuOpacity(0)
      setSideMenuHeight(0)
    }
  }

  return (
    <Wrapper>
      <WrapperMenu>
        <ButtonClose onClick={OpenSideMenu}>
          {sideMenuOpacity === 0 ? <AiOutlineMenu /> : <AiOutlineCloseCircle />}
        </ButtonClose>
      </WrapperMenu>
      <SideMenu
        Opa={sideMenuOpacity}
        height={sideMenuHeight + '%'}
        onHideMenu={OpenSideMenu}
        onModalDelivery={setModalDeliveryShow}
        onModalSchedule={setScheduleShow}
        onModalRecommendation={setModalShow}
      />
      <Title onClick={TitleHandler}>Realiza tu orden a domicilio <Icon src={require('../Images/farmacvs_GxD_webdesign_headerarrow_png-01.png')} /></Title>
      <ModalDelivery
        show={modalDeliveryShow}
        onHide={() => setModalDeliveryShow(false)}
      />
      <ModalScheduleDate
        show={scheduleShow}
        onHide={() => setScheduleShow(false)}
      />
      <ModalRecommendation
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Wrapper>
  )
}