import React, { useState } from 'react'
import * as emailjs from 'emailjs-com'
import styled from 'styled-components'
import { useFormik } from 'formik';
import { AiFillCaretDown } from "react-icons/ai";
import ReactModal from 'react-modal';


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-bottom: ${props => `1px solid ${props.theme.colors.CustomGrayFont}`};
`;


const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: ${props => props.theme.fonts[1]};
  text-transform: uppercase;
  color: ${props => props.theme.colors.CustomRed};  

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.2rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){}
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1.4rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .9rem;
  }
`;

const Subtitle = styled.div`
  color: ${props => props.theme.colors.CustomGrayFont};
  font-family: ${props => props.theme.fonts[0]};

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: .8rem;
    padding: 25px 25px; 
  }
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1rem;
    margin-top: 20px; 
    padding: 0 70px;  
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .7rem;  
    margin-top: 20px; 
    margin-bottom: 20px; 
    padding: 0 15px; 
  }
`;

const ButtonClose = styled.button`
    border: none !important;
    position: absolute;
    right: 1.5rem;
    background-color: transparent;
    color: white;
    font-size: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &:hover{
      background-color: rgb(208, 211, 212, .5);
    };
    &:focus{
      outline: none;
      background-color: transparent;
    };
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 32px;
    height: 32px;
    padding: 20px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 24px;
    height: 24px;
    padding: 16px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 24px;
    height: 24px;
    padding: 20px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 24px;
    height: 24px;
    padding: 0px;
  }
`;

const Icon = styled.img`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 32px;
    height: 32px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 24px;
    height: 24px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 32px;
    height: 32px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 24px;
    height: 24px;
  }
`;

const Row = styled.div`
  width: 50%;
  content: "";
  display: table;
  clear: both;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 80%;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: contents;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 6px 20px 5px 20px;
  color: ${props => props.theme.colors.CustomGrayFont};
  border: ${props => `1px solid ${props.theme.colors.CustomGrayFont}`};
  font-family: ${props => props.theme.fonts[0]};
  border-radius: 25px;
  box-sizing: border-box;
  resize: vertical;
  outline: none;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){}
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){}  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .7rem; 
    padding: 8px 20px 8px 20px;
  }
`;

const ColumnOne = styled.div`
  float: left;
  margin-right: 5;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 45%;
    margin-top: 15px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){}
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 45%;
    margin-top: 15px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 90%;
    margin-top: 15px;  
  }
`;

const ColumnTwo = styled(ColumnOne)`
  float: right;
  margin-left: 5;
  text-align: left;
`;

const ColumnTwoTop = styled(ColumnTwo)`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    margin-top: 50px;
    text-align: right;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){}
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    margin-top: 50px;
    text-align: right;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-top: 50px;
    text-align: center;
  }
`;

const Button = styled.button`
  font-family: ${props => props.theme.fonts[1]};  
  color: ${props => props.theme.colors.CustomRed};
  background-color: white;
  padding: 10px 60px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0 rgba(2,119,255,0.15), 0 2px 5px 0 rgba(2,119,255,0.02);
  border: none !important;
  outline: none !important;
  &:hover{
    outline: none !important;
    background-color: ${props => props.theme.colors.CustomAqua};
  };
  &:focus:{
    outline: none !important;
    background-color: transparent;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1rem;
    padding: 10px 60px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){}
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){}  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 100%;
    font-size: .9rem; 
    padding: 10px 20px;  
  }
`;

const ModalImage = styled.img`
  margin-top: -50px;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width:35%; 
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 50%; 
  }   

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`;

const FooterWrap = styled.div`
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    position: relative;
  }
`;

const FooterLine = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.CustomAquaOrigin};
  color: white;
  font-family: ${props => props.theme.fonts[0]};
  font-size: 1rem; 
  text-align: center;

 /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    padding: 10px;
    font-size: 1rem;
    margin: 10px 0 15px 0;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){}
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 10px;
    font-size: 1rem;
    margin: 10px 0 15px 0;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  } 
`;

const LogoWrap = styled.div`
  text-align: right;
  margin-top: 40px;
  padding-right: 60px;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding-right: 20px;
  }
`;

const Logo = styled.img`
  width: 16%;
  margin-left: -.5%;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 25%;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 60%;
  }
`;

const LabelError = styled.div`
  color: ${props => props.theme.colors.CustomRed};
  padding-left: 10px;  
  font-family: ${props => props.theme.fonts[1]};

 /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: .7rem;
    padding-top: 5px;  
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .6rem;
    padding-top: 3px;  
  }
`;

const LabelFileUpload = styled.label`
  cursor: pointer;
  width: 100%;
  padding: 6px 20px 5px 20px;
  border: ${props => `1px solid ${props.theme.colors.CustomGrayFont}`};
  font-family: ${props => props.theme.fonts[0]};
  color: ${props => props.theme.colors.CustomGrayFont};
  border-radius: 25px;
  box-sizing: border-box;
  resize: vertical;
  outline: none;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .7rem; 
    padding: 8px 20px 8px 20px;
  }
`;

const InputFileUpload = styled.input`
  opacity: 0; 
  position: absolute;
  z-index: -1;
  right: 50px;
`;

const InputSelect = styled.select`
  width: 100%;
  border: none;
  font-family: ${props => props.theme.fonts[0]};
  color: ${props => props.theme.colors.CustomGrayFont};
  border-radius: 25px;
  box-sizing: border-box;
  resize: vertical;
  outline: none;
  -webkit-appearance: none;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .7rem; 
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: ${props => `1px solid ${props.theme.colors.CustomGrayFont}`};
  color: ${props => props.theme.colors.CustomGrayFont};
  font-family: ${props => props.theme.fonts[0]};
  border-radius: 25px;
  box-sizing: border-box;
  resize: vertical;
  outline: none;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 100%;
    padding: 6px 20px 5px 20px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){}
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){  
    width: 100%;
    padding: 6px 20px 5px 20px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 100%;
    padding: 8px 20px 8px 20px;
  }
`;

const InputNumber = styled(Input)`
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  },
  -moz-appearance: textfield;
`;

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Requerido';
  } else if (values.firstName.length > 15) {
    errors.firstName = 'Debe contener 15 caracteress o menos';
  }

  if (!values.lastName) {
    errors.lastName = 'Requerido';
  } else if (values.lastName.length > 20) {
    errors.lastName = 'Debe contener 20 caracteres o menos';
  }

  if (!values.email) {
    errors.email = 'Requerido';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección de Correo Invalida';
  }

  if (!values.age) {
    errors.age = 'Requerido';
  } else if (values.age < 18) {
    errors.age = 'Debe ser mayor de 18 años';
  }

  if (!values.gender) {
    errors.gender = 'Requerido';
  }

  if (!values.dni) {
    errors.dni = 'Requerido';
  } else if (values.dni.length > 8) {
    errors.dni = 'Debe contener 8 caracteres o menos';
  }

  if (!values.phone) {
    errors.phone = 'Requerido';
  } else if (values.phone.length === 12) {
    errors.phone = 'Debe contener 12 caracteres';
  }

  return errors;
};


export const ModalEmployment = (props) => {
  ReactModal.setAppElement('#root');
  
  const [uploadFileLabel, setUploadFileLabel] = useState("Adjuntar CV");
  const [uploadFile, setUploadFile] = useState();
  const [errorUploadFile, setErrorUploadFile] = useState();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      age: '',
      gender: '',
      dni: '',
      phone: ''
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      if (!uploadFile) {
        setErrorUploadFile("Required")
      }

      console.log('The file was set ', uploadFile)
      values['curriculum'] = "STRING"//uploadFile;
      //alert(JSON.stringify(values, null, 2));



      let templateParams2 = {
        user_email: values.firstName,
        full_name: values.firstName + ' ' + values.lastName,
        first_name: values.firstName,
        last_name: values.lastName,
        age: values.age,
        sex: values.gender,
        dni: values.dni,
        phone: values.phone,
      }

      try {
        console.log('Enviando correo...')
        emailjs.send("farmacia_cvs", "farma_employment", templateParams2, "user_zaPMM6ASvYBdDsfqkZ2Yc");
      } catch (err) {
        console.log(err)
      } finally {
        resetForm({ values: '' })
        props.onHide();
      }
    },
  });

  const FileUploadHandler = (e) => {
    const file = e.target.files[0];
    setUploadFile(file)
    setUploadFileLabel(e.target.files[0].name)
  }

  return (
    <ReactModal
      isOpen={props.show}
      className="schedule-modal"
      overlayClassName="delivery-overlay"
      onRequestClose={props.onHide} 
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <Wrapper>
        <Title>Empleo</Title>
        <ButtonClose onClick={props.onHide}>
          <Icon src={require('../Images/farmacvs_GxD_webdesign_png_xmodal_32x32.png')} />
        </ButtonClose>
      </Wrapper>
      <BodyWrapper>
        <Subtitle>Si deseas formar parte de nuestro equipo, llena este formulario y adjunta tu curriculum.</Subtitle>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Row>
            <ColumnOne>
              <Input
                id="firstName"
                name="firstName"
                placeholder="Nombre"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName} />
              {formik.touched.firstName && formik.errors.firstName ? <LabelError>{formik.errors.firstName}</LabelError> : null}
            </ColumnOne>
            <ColumnTwo>
              <Input
                id="lastName"
                name="lastName"
                placeholder="Apellido"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName} />
              {formik.touched.lastName && formik.errors.lastName ? <LabelError>{formik.errors.lastName}</LabelError> : null}
            </ColumnTwo>
          </Row>
          <Row>
            <ColumnOne>
              <InputNumber
                id="age"
                name="age"
                placeholder="Edad"
                type="text"
                maxLength={3}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.age} />
              {formik.touched.age && formik.errors.age ? <LabelError>{formik.errors.age}</LabelError> : null}
            </ColumnOne>
            <ColumnTwo>
              <InputContainer>
                <InputSelect
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ display: 'block' }}
                >
                  <option value="" label="Sexo" />
                  <option value="M" label="Masculino" />
                  <option value="F" label="Femenino" />
                </InputSelect>
                <AiFillCaretDown />
              </InputContainer>
              {formik.touched.gender && formik.errors.gender ? <LabelError>{formik.errors.gender}</LabelError> : null}
            </ColumnTwo>
          </Row>
          <Row>
            <ColumnOne>
              <InputNumber
                id="dni"
                name="dni"
                placeholder="Cédula"
                type="text"
                maxLength={8}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dni} />
              {formik.touched.dni && formik.errors.dni ? <LabelError>{formik.errors.dni}</LabelError> : null}
            </ColumnOne>
            <ColumnTwo>
              <Input
                id="email"
                name="email"
                placeholder="Correo"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email} />
              {formik.touched.email && formik.errors.email ? <LabelError>{formik.errors.email}</LabelError> : null}
            </ColumnTwo>
          </Row>
          <Row>
            <ColumnOne>
              <InputNumber
                id="phone"
                name="phone"
                placeholder="Teléfono"
                type="number"
                maxLength={12}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone} />
              {formik.touched.phone && formik.errors.phone ? <LabelError>{formik.errors.phone}</LabelError> : null}
            </ColumnOne>
            <ColumnTwo>
              <LabelFileUpload htmlFor="cv">{uploadFileLabel}</LabelFileUpload>
              <InputFileUpload type="file" id="cv" name="cv" onChange={FileUploadHandler} />
              {errorUploadFile ? <LabelError>{errorUploadFile}</LabelError> : null}
            </ColumnTwo>
          </Row>
          <Row>
            <ColumnTwoTop>
              <Button type="submit">Enviar</Button>
            </ColumnTwoTop>
          </Row>
        </form>
      </BodyWrapper>
      <ModalImage src={require("../Images/farmacvs_GxD_webdesign_512X512_empleo.png")} alt="" />
      <FooterWrap>
        <LogoWrap>
          <Logo src={require('../Images/farmacvs_GxD_logopuyao_png_512x512.png')} alt="" />
        </LogoWrap>
        <FooterLine>!Envía tus datos y únete a nuestro equipo!</FooterLine>
      </FooterWrap>
    </ReactModal>
  )
}