import { createGlobalStyle } from 'styled-components';

import TexGyreAdventorRegular from './texgyreadventor-regular.woff'
import TexGyreAdventorBold from './texgyreadventor-bold.woff'

export default createGlobalStyle`
  @font-face {
    font-family: 'Tex Gyre Adventor Regular';
    font-style: normal;
    font-weight: normal;
    src: url(${TexGyreAdventorRegular}) format('woff');
  }

  @font-face {
    font-family: 'Tex Gyre Adventor Bold';
    font-style: normal;
    font-weight: normal;
    src: url(${TexGyreAdventorBold}) format('woff');
  }

  body{
    font-family: "Tex Gyre Adventor Regular";
    
    ::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
`;