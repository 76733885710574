import React from 'react'
import styled from 'styled-components'
import { Header } from '../Components/Header';

const Wrapper = styled.div({
  position: 'relative',
  width: "100%",
  height: "100vh"
})

const SubWrapper = styled.div`
  padding: 0 60px;
`

const Title = styled.div`
  color: ${props => props.theme.colors.CustomRed};
  font-family: ${props => props.theme.fonts[1]};
  font-size: 3rem;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Paragraph = styled.p`
  width: 60%;
`;

export const Responsability = () => {
  return (
    <Wrapper>
      <Header />
      <SubWrapper>
        <Title>Responsabilidad Social Empresarial</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit id risus eu lobortis. Donec egestas nec tortor sed convallis. Nam id bibendum lacus. Pellentesque suscipit volutpat sollicitudin. Praesent non felis vitae odio venenatis lobortis. Fusce semper, nisl eu vehicula pellentesque, eros nisi dapibus nisl, sit amet convallis odio metus ac nunc. Etiam lobortis arcu ut leo vehicula, id tempor dolor fermentum. Maecenas cursus vel dolor eget blandit. Quisque dapibus fringilla dolor, a suscipit quam ultricies quis. Suspendisse non posuere lorem, ut pulvinar augue. Aliquam ac ex non urna varius volutpat. Praesent finibus sollicitudin iaculis. Nullam sed ultrices libero. Etiam vitae libero nec dolor semper fermentum. Aliquam hendrerit porttitor nisl id molestie.
        </Paragraph>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit id risus eu lobortis. Donec egestas nec tortor sed convallis. Nam id bibendum lacus. Pellentesque suscipit volutpat sollicitudin. Praesent non felis vitae odio venenatis lobortis. Fusce semper, nisl eu vehicula pellentesque, eros nisi dapibus nisl, sit amet convallis odio metus ac nunc. Etiam lobortis arcu ut leo vehicula, id tempor dolor fermentum. Maecenas cursus vel dolor eget blandit. Quisque dapibus fringilla dolor, a suscipit quam ultricies quis. Suspendisse non posuere lorem, ut pulvinar augue. Aliquam ac ex non urna varius volutpat. Praesent finibus sollicitudin iaculis. Nullam sed ultrices libero. Etiam vitae libero nec dolor semper fermentum. Aliquam hendrerit porttitor nisl id molestie.
        </Paragraph>
      </SubWrapper>
    </Wrapper>
  )
};