import React from 'react'
import styled from 'styled-components';

const ImageCustom = styled.img`
  width: 40%;
  float: right;
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 70%;
    height: 100%;
  } 
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 70%;
    height: 100%;
  }
`;

const CardText = styled.div`
  position: absolute;
  font-family: ${props => props.theme.fonts[1]};
  color: ${props => props.theme.colors.CustomBlack};
  top: 8px;
  left: 16px;
  transition: .5s ease;
  backface-visibility: hidden;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.2rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: 1.1rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: .6rem;
    left: 10px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .6rem;
    left: 10px;
  }
`;

const CardCustom = styled.div`
  position: relative;
  width: 33.33%;  
  transition: .5s ease;
  backface-visibility: hidden;
  ${'' /* &:hover {
    opacity: 1;
    background: rgb(220,220,220)
  };
  &:hover ${ImageCustom} {
    opacity: 0.6;
  };
  &:hover ${CardText} {
    opacity: 0.6;
  }; */}
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: inline-block;
    width: 20%;
    height: 130px;
    margin: 0 10px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  } 
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: inline-block;
    width: 40%;
    height: 130px;
    margin: 0 10px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  }
`;

export const Card = (props) => {
  return (
    <CardCustom>
      <ImageCustom src={props.imagePath} alt={props.title} />
      <CardText>{props.title}</CardText>
    </CardCustom>
  )
}

