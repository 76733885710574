import React, { Fragment } from 'react';
import { Router, Route, Switch } from 'react-router-dom'
import Theme from './Theme/theme'
import GlobalFonts from './Fonts/fonts';
import { Home } from './Views/Home';
import { AboutUs } from './Views/AboutUs';
import { Policy } from './Views/Policy';
import { Responsability } from './Views/Responsability';
import { Promotion } from './Views/Promotion'
import { NotFound } from './Views/NotFound';
import history from './History';
import ScrollToTop from './Utils/ScrollToUp'

import "react-datepicker/dist/react-datepicker.css";
import './App.css';

function App() {
  return (
    <Theme>
      <Fragment>
        <GlobalFonts />
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/About" component={AboutUs} />
            <Route path="/Policy" component={Policy} />
            <Route path="/Responsability" component={Responsability} />
            <Route path="/Promotion" component={Promotion} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Fragment>
    </Theme>
  );
}

export default App;
