import React, { useState } from 'react'
import styled from 'styled-components';
import { ModalDelivery } from './ModalDelivery';
import { ModalScheduleDate } from './ModalScheduleDate';
import history from '../History';

const Wrapper = styled.div`
  @media (min-width: 1025px){
    padding: 0 60px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){}

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 0 0;
    display: flex;
    flex-direction: column-reverse;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 0 0;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    padding: 40px 100px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    padding: 30px 80px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 40px 20px 30px 20px;
    overflow: auto;
    white-space: nowrap;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 40px 0px 30px 0px;
    overflow: auto;
    white-space: nowrap;
  }
`;

const Button = styled.button`
  display: inline-block;
  font-family: ${props => props.theme.fonts[1]};  
  color: ${props => props.theme.colors.CustomRed};
  background-color: white; 
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0 rgba(2,119,255,0.15), 0 2px 5px 0 rgba(2,119,255,0.02);
  border: none !important;
  outline: none !important;
  &:hover{
    outline: none !important;
    background-color: ${props => props.theme.colors.CustomAqua};
  };
  &:focus:{
    outline: none !important;
    background-color: transparent;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.4rem;
    padding: 20px 45px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: 1.3rem;
    padding: 10px 23px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1rem;
    padding: 10px 20px;
    margin: 0 10px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .7rem;
    padding: 5px 10px;
    margin: 0 20px;
  }
`;

const Banner = styled.img`
  width: 100%;
  background-repeat: no-repeat;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    border-radius: 0px;
    margin-top: 10px;
    padding: 0 10px;
  } 
    /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    border-radius: 0px;
    margin-top: 10px;
    padding: 0 10px;
  }
`;

export const FirstSection = () => {
  const [modalShow, setModalShow] = useState(false);
  const [scheduleShow, setScheduleShow] = useState(false);

  const DeliveryButtonHandler = () => {
    setModalShow(true);
  }

  const ScheduleButtonHandler = () => {
    setScheduleShow(true);
  }

  const PromotionButtonHandler = (e) => {
    history.push('/Promotion')
  }

  return (
    <Wrapper>
      <WrapperButtons>
        <Button onClick={ScheduleButtonHandler}>Agendar Consultas</Button>
        <Button onClick={DeliveryButtonHandler}>Entregas a Domicilio</Button>
        <Button onClick={PromotionButtonHandler}>Promociones</Button>
      </WrapperButtons>
      <picture>
        <source media="(min-width: 768px ) and (max-width: 1024px)" srcSet={require('../Images/Tablet/farmacvs_GxD_jpg_examenesbanner_tablet.jpg')} />
        <source media="(max-width: 760px)" srcSet={require('../Images/Mobile/farmacvs_GxD_jpg_examenesbanner_mobile.jpg')} />
        <Banner src={require("../Images/farmacvs_GxD_jpg_examenesbanner.jpg")} alt="Apertura CCCT"/>
      </picture>
      <ModalDelivery
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ModalScheduleDate
        show={scheduleShow}
        onHide={() => setScheduleShow(false)}
      />
    </Wrapper>
  )
}