import React, { Fragment } from 'react'

import { RedHeader } from './RedHeader'
import { GrayHeader } from './GrayHeader'
import { WhiteHeader } from './WhiteHeader'

export const Header = (props) => {
  return (
    <Fragment>
      <RedHeader />
      <GrayHeader />
      <WhiteHeader hideIt={props.hideIt} />
    </Fragment>
  )
}