import React, { useState } from 'react'
import styled from 'styled-components'
import { SiInstagram } from "react-icons/si";
import ReactPixel from 'react-facebook-pixel';

import history from '../History';
import { ModalEmployment } from './ModalEmployment';
import { ModalScheduleDate } from './ModalScheduleDate';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.CustomBlueFooter};

  @media (min-width: 1025px){
    padding: 50px 60px 50px 60px;    
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){}

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    border-radius: 25px 25px 0 0;
    padding: 30px 20px 30px 20px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    border-radius: 25px 25px 0 0;
    padding: 20px 20px 20px 20px;
  }
`;

const SubWrapper = styled.div`
  display: flex;  
  justify-content: space-between;
  flex-direction: row;

  @media (min-width: 1025px){  
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){}

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    justify-content: space-evenly;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    flex-direction: row-reverse;
  }
`;

const BottomWrapper = styled.div`
  color: white;
  font-size: .5rem;  
  justify-content: space-between;
  align-items: center;
  display: none;
  margin-top: 15px;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: flex;
    justify-content: center;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: flex;
  }
`;

const Instagram = styled.div`
  color: white; 
  display: inherit;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    margin-left: 20px;
  } 

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: 1.1rem;
  }
`;

const WrapperColumn = styled.div``;

const WrapperHiddenColumn = styled.div`
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none
  } 
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none
  }
`;

const Text = styled.div`
  color: ${props => props.theme.colors.CustomGray};

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: .9rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: .6rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .55rem;
  }
`;

const TextTitle = styled(Text)`
  margin-bottom: 10px;
  font-family: ${props => props.theme.fonts[1]};

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-bottom: 5px;      
  }
`;

const TextOption = styled(Text)`
  margin-top: 5;
  margin-bottom: 10px;
  font-family: ${props => props.theme.fonts[0]};

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-bottom: 5px;      
  }
`;

const TextNumber = styled(TextOption)`
  font-family: ${props => props.theme.fonts[1]}
`;

const TextClick = styled(TextOption)`
  cursor: pointer;
  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

const TextHiddenClick = styled(TextClick)`
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none
  }
`;

const RowWrapper = styled.div`
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: flex;
    flex-direction: row;
  } 
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: flex;
    flex-direction: row;
  }
`;

const Links = styled.a`
  color: white;
  &:hover {
    color: white;
  }
`;

export const Footer = () => {
  const [modalShow, setModalShow] = useState(false);
  const [scheduleShow, setScheduleShow] = useState(false);

  const EmploymentHandler = () => {
    setModalShow(true);
  }

  const ScheduleButtonHandler = () => {
    setScheduleShow(true);
  }

  const PromotionHandler = () => {
    history.push('/Promotion')
  }

  const AbouHandler = () => {
    history.push('/About')
  }

  const PolicyHandler = () => {
    history.push('/Policy')
  }

  const WhatsAppButton = () => {
    ReactPixel.track('Contact', {
      content_name: "Footer"
    })
  }

  return (
    <Wrapper>
      <SubWrapper>
        <WrapperHiddenColumn>
          <TextTitle>OFERTAS</TextTitle>
          <TextClick onClick={PromotionHandler}>Farmacia</TextClick>
          <TextClick onClick={PromotionHandler}>Nutrición</TextClick>
          <TextClick onClick={PromotionHandler}>&Oacute;ptica</TextClick>
          <TextClick onClick={PromotionHandler}>Articulos del Hogar</TextClick>
          <TextClick onClick={ScheduleButtonHandler}>Exámenes Médicos</TextClick>
        </WrapperHiddenColumn>
        <WrapperHiddenColumn>
          <TextTitle>EN TIENDA</TextTitle>
          {/* <TextOption>Buscador de Productos</TextOption> */}
          <TextClick onClick={PromotionHandler}>Novedades y promociones</TextClick>
          <br />
          <TextTitle>PROGRAMAS</TextTitle>
          <TextClick onClick={ScheduleButtonHandler}>Agendar consultas</TextClick>
        </WrapperHiddenColumn>
        <WrapperColumn>
          <TextTitle>CONTACTO</TextTitle>
          <TextHiddenClick onClick={ScheduleButtonHandler}>Planilla de Contacto</TextHiddenClick>
          <RowWrapper>
            <TextOption>Numero de Contacto:&nbsp;</TextOption>
            <TextOption>0212-9596659</TextOption>
          </RowWrapper>
          <TextOption>Dirección: CCCT, Nivel C1</TextOption>
          <TextNumber><b>WhatsApp compras:</b></TextNumber>
          <TextNumber><Links onClick={WhatsAppButton} href="https://wa.me/584122090826" target="_blank"><b>(+58 412 2090826)</b></Links></TextNumber>
          <RowWrapper>
            <TextOption>Correo electrónico:&nbsp;</TextOption>
            <TextOption>info@farmaciacvs.com</TextOption>
          </RowWrapper>
        </WrapperColumn>
        <WrapperColumn>
          <TextTitle>INFORMACION EMPRESARIAL</TextTitle>
          <TextClick onClick={AbouHandler}>Quiénes somos</TextClick>
          <TextClick onClick={EmploymentHandler}>Empleo</TextClick>
          {/* <TextOption><Links href='/Responsability'>Responsabilidad social empresarial</Links></TextOption> */}
          <TextClick onClick={PolicyHandler}>Políticas de Privacidad</TextClick>
        </WrapperColumn>
      </SubWrapper>
      <BottomWrapper>
        <div>Copyright 2020-2021</div>
        <a href="https://www.instagram.com/farmacvs/" rel="noopener noreferrer" target="_blank"><Instagram><SiInstagram /></Instagram></a>
      </BottomWrapper>
      <ModalEmployment
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ModalScheduleDate
        show={scheduleShow}
        onHide={() => setScheduleShow(false)}
      />
    </Wrapper>
  )
}