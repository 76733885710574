import React from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal';
import ReactPixel from 'react-facebook-pixel';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-family: ${props => props.theme.fonts[1]};
  text-transform: uppercase;
  color: ${props => props.theme.colors.CustomRed};  

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.2rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    font-size: 1rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    font-size: 1.4rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: .9rem;
  }
`;

const ButtonClose = styled.button`
    border: none !important;
    position: absolute;
    right: 0px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &:hover{
      background-color: rgb(208, 211, 212, .5);
    };
    &:focus{
      outline: none;
      background-color: transparent;
    };
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 32px;
    height: 32px;
    padding: 20px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 24px;
    height: 24px;
    padding: 16px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 24px;
    height: 24px;
    padding: 20px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 24px;
    height: 24px;
    padding: 0px;
  }
`;

const Icon = styled.img`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 32px;
    height: 32px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 24px;
    height: 24px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 32px;
    height: 32px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 24px;
    height: 24px;
  }
`;

const FooterNumber = styled.div`
  color: ${props => props.theme.colors.CustomRed};
  font-family: ${props => props.theme.fonts[1]};
  font-weight: bold;
  font-size: 1.8rem;
  text-align: right;
  margin-right: 4%;
  margin-bottom: 3%;
  margin-top: -2%;
  text-shadow: 2px 2px rgba(0,0,0,0.25);

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`;

const Links = styled.a`
  &:hover {
    color: none;
    text-decoration: none;
  }
`;

const ModalImage = styled.img`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 100%;
  }
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 100%;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 100%;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 100%;
  }
`;


export const ModalDelivery = (props) => {
  ReactModal.setAppElement('#root');

  const WhatsAppButton = () => {
    ReactPixel.track('Contact',{
      content_name: "Delivery Modal"
    })
  }

  return (
    <ReactModal
      isOpen={props.show}
      className="delivery-modal"
      overlayClassName="delivery-overlay"
      onRequestClose={props.onHide}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <Wrapper>
        <Title>Entregas a Domicilio</Title>
        <ButtonClose onClick={props.onHide}>
          <Icon src={require('../Images/farmacvs_GxD_webdesign_png_xmodal_32x32.png')} alt="Cerrar" />
        </ButtonClose>
      </Wrapper>
      <picture>
        <source media="(min-width: 768px ) and (max-width: 1024px)" srcSet={require('../Images/Tablet/farmacvs_GxD_webdesign_png_deliverybanne_tablet_1024x710.png')} />
        <source media="(max-width: 760px)" srcSet={require('../Images/Mobile/farmacvs_GxD_webdesign_png_mobiledelivery_mobile_768x1280.png')} />
        <ModalImage src={require('../Images/farmacvs_GxD_png_deliverybanner_1024x710_web.png')} alt="Delivery" />
      </picture>
      <Links onClick={WhatsAppButton} href="https://wa.me/584122090826" target="_blank">
        <FooterNumber>+58 412 2090826</FooterNumber>
      </Links>
    </ReactModal>
  )
}