import React from 'react'
import styled from 'styled-components'
import { Card } from './Card';
import { MdKeyboardArrowRight } from "react-icons/md";

const Wrapper = styled.div`
  @media (min-width: 1025px){
    padding: 0px 60px 50px 60px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){}

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 20px 20px 60px 20px;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 20px 20px 20px 20px;
  }
`;

const WrapperHorizontal = styled.div`
  @media (min-width: 1025px){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    overflow: auto;
    white-space: nowrap;
    display: inline-block;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    overflow: auto;
    white-space: nowrap;
    display: inline-block;
  }
  
`

const WrapperRow = styled.div`
  display:flex;
  flow-direction: row;
  width: 100%;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: inline-block;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: inline-block;
  }
`;

const ShowMobile = styled.div`
  display: none;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: inline-block;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: inline-block;
  }
`;

const WrapperRowMiddle = styled.div`
  display:flex;
  flow-direction: row;
  margin-top: 10px; 
  margin-bottom: 10px;
  width: 100%;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`;

const WrapperRowFinal = styled.div`
  display:flex;
  flow-direction: row;
  width: 100%;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`;

const WrapperTitle = styled.div`
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.div`
  padding-top: 2%;
  padding-bottom: 1%;
  text-align: center;
  font-family: ${props => props.theme.fonts[1]};

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.4rem;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: 1.3rem;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`;

const TitleMobile = styled(Title)`
  display: none;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: block;
    padding-bottom: 30px;
    font-size: .9rem;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: block;
    padding-bottom: 10px;
    font-size: .8rem;
  }
`;

const Subtitle = styled.div`
  display: none;
  padding-top: 2%;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: block;
    font-size: .6rem;
    padding-bottom: 10px;
    text-align: center;
    color: ${props => props.theme.colors.CustomGrayFont};
    font-family: ${props => props.theme.fonts[1]};
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: block;
    font-size: .6rem;
    padding-bottom: 10px;
    text-align: center;
    color: ${props => props.theme.colors.CustomGrayFont};
    font-family: ${props => props.theme.fonts[1]};
  }
`;

const WrapperBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2%;
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`;

const BottomImage = styled.img`
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    width: 8%
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 7%
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 6%
  }
`;


export const SecondSection = () => {
  return (
    <Wrapper id="SECOND_SECTION">
      <WrapperTitle>
        <Title>Compra por categoría</Title>
        <TitleMobile>Categorías</TitleMobile>
        <Subtitle>Ver mas <MdKeyboardArrowRight size={16} /></Subtitle>
      </WrapperTitle>
      <WrapperHorizontal>
        <WrapperRow>
          <Card imagePath={require('../Images/farmacvs_GxD_webdesign_512X512_productspng_belleza.png')} title="Belleza +" />

          <Card imagePath={require('../Images/farmacvs_GxD_webdesign_png_medicine.png')} title="Salud y Medicina +" />

          <Card imagePath={require('../Images/farmacvs_GxD_webdesign_512X512_productspng_vitaminas.png')} title="Vitaminas +" />

          <ShowMobile>
            <Card imagePath={require('../Images/farmacvs_GxD_webdesign_512X512_productspng_alimentosyb.png')} title="Alimentos y Bebidas +" />

            <Card imagePath={require('../Images/farmacvs_GxD_webdesign_png_home.png')} title="Artículos del Hogar +" />

            <Card imagePath={require('../Images/farmacvs_GxD_webdesign_png_personalcare.png')} title="Cuidado Personal +" />

            <Card imagePath={require('../Images/farmacvs_GxD_webdesign_512X512_gripe.png')} title="Gripe y Resfriados +" />

            <Card imagePath={require('../Images/farmacvs_GxD_webdesign_png_kids.png')} title="Cuidado Infantil +" />

            <Card imagePath={require('../Images/farmacvs_GxD_webdesign_512X512_productspng_mascotas.png')} title="Mascotas +" />
          </ShowMobile>
        </WrapperRow>

        <WrapperRowMiddle>
          <Card imagePath={require('../Images/farmacvs_GxD_webdesign_512X512_productspng_alimentosyb.png')} title="Alimentos y Bebidas +" />

          <Card imagePath={require('../Images/farmacvs_GxD_webdesign_png_home.png')} title="Artículos del Hogar +" />

          <Card imagePath={require('../Images/farmacvs_GxD_webdesign_png_personalcare.png')} title="Cuidado Personal +" />
        </WrapperRowMiddle>
        <WrapperRowFinal>
          <Card imagePath={require('../Images/farmacvs_GxD_webdesign_512X512_gripe.png')} title="Gripe y Resfriados +" />

          <Card imagePath={require('../Images/farmacvs_GxD_webdesign_png_kids.png')} title="Cuidado Infantil +" />

          <Card imagePath={require('../Images/farmacvs_GxD_webdesign_512X512_productspng_mascotas.png')} title="Mascotas +" />
        </WrapperRowFinal>
      </WrapperHorizontal>
      <WrapperBottom>
        <BottomImage src={require('../Images/farmacvs_GxD_flavicon_png_110x110.png')} alt="More Content" />
      </WrapperBottom>
    </Wrapper>
  )
}